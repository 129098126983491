/* Header.css */

.navbar-custom {
  background-color: #ffffff; /* 白色背景 */
  box-shadow: 0px 4px 2px -2px gray; /* 添加下方阴影 */
  height: 100px; /* 增加导航栏高度 */
  padding: 0;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand img {
  margin-right: 5px;
  height: 80px; /* 增加 logo 高度 */
  width: auto; /* 保持图片宽高比 */
}

.navbar-brand span {
  font-size: 2rem; /* 调整网页名称的字体大小 */
  color: #333; /* 深灰色 */
}

.nav-link-custom {
  font-size: 2rem; /* 调整导航链接的字体大小 */
  font-weight: bold;
  color: #333; /* 修改为深灰色文本 */
  transition: color 0.3s ease-in-out;
  padding-bottom: 10px; /* 添加下方内边距以向下对齐 */
}

.nav-link-custom:hover {
  color: #B3EFF0; /* 悬停时的颜色 */
  border-bottom: 1px dashed #B3EFF0; /* 悬停时添加底部虚线边框 */
}

.logo-image {
  height: 90px; /* 确保 logo 高度适当 */
  width: auto; /* 保持图片宽高比 */
}

.website-name {
  font-size: 1.75rem; /* 确保网页名称字体大小 */
}

.content-wrapper {
  background-color: #f0f0f0; /* 浅灰色背景 */
  min-height: 100vh; /* 最小高度 100% 视口高度 */
  padding: 1rem; /* 16px */
  max-width: 80rem; /* 1280px */
  margin: auto;
}
.logo-image {
    box-shadow: none; /* 确保没有阴影 */
}

