/* 容器样式，增加内部间距和阴影 */
.container {
  padding: 20px;
  background-color: #ffffff; /* 白色背景 */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* 轻微的阴影 */
  border-radius: 8px; /* 圆角边框 */
}

/* 表单元素通用样式 */
.form-control, .multiselect-container {
  border-radius: 4px; /* 圆角边框 */
  border: 1px solid #ced4da; /* 统一边框颜色 */
}

/* 调整下拉多选框的样式 */
.multiselect-container {
  width: 100%; /* 使下拉框宽度与输入框相同 */
}

/* 按钮样式 */
.btn-primary {
  background-color: #007bff; /* 蓝色背景 */
  border: none; /* 去除边框 */
  padding: 10px 20px; /* 增加内边距 */
  margin-top: 10px; /* 在按钮上方添加间距 */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* 按钮添加阴影 */
}

/* 增加表单组件间的间距 */
.form-group {
  margin-bottom: 15px; /* 增加底部外边距 */
}

/* 标签样式 */
.form-label {
  color: #495057; /* 深灰色字体 */
  font-weight: bold; /* 加粗字体 */
}
.graph-placeholder {
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Inputquery.css */
.intro-container {
  background-color: #f8f9fa; /* 浅灰色背景 */
  padding: 20px; /* 内边距 */
  border-radius: 5px; /* 轻微的圆角 */
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* 轻微的阴影 */
}

h1 {
  color: #007bff; /* Bootstrap primary color */
  font-weight: bold;
}

p {
  font-size: 1.2rem;
  color: #666; /* Dark gray for text */
}
/* 在 CSS 文件中添加或修改 */
.navbar {
  background-color: #ffffff; /* 使用纯白或其他更轻的颜色背景 */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 添加微妙的阴影以提升层次感 */
}

.nav-link {
  color: #333333; /* 使用深灰色而非纯黑色，看起来更柔和 */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* 更现代的字体 */
  transition: all 0.3s ease-in-out; /* 平滑过渡动画 */
}

.nav-link:hover {
  color: #0056b3; /* 悬停时颜色变化 */
  border-bottom: 3px solid #0056b3; /* 突出显示悬停效果 */
}
.nav-link {
  padding: 10px 15px;
  position: relative;
  overflow: hidden; /* 确保伪元素不会溢出链接区域 */
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #395378;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.nav-link:hover::after {
  transform: scaleX(1); /* 悬停时填充下划线 */
}
.nav {
  justify-content: center; /* 居中导航链接 */
}

.nav-link {
  margin: 0 10px; /* 增加链接之间的间隔 */
}
@media (max-width: 768px) {
  .navbar {
    padding: 10px; /* 小屏设备上减少内边距 */
  }

  .navbar-toggler {
    display: block; /* 小屏设备上显示汉堡菜单 */
  }
}

/* Inputquery.css */

.intro-container {
  background-color: #f7f7f7; /* 背景色 */
  padding: 40px; /* 内边距 */
  border-radius: 10px; /* 圆角 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 阴影 */
  text-align: center; /* 文本居中 */
}

.intro-title {
  font-size: 2.5rem; /* 标题字体大小 */
  font-weight: bold; /* 加粗 */
  color: #333; /* 字体颜色 */
  margin-bottom: 20px; /* 底部外边距 */
}

.intro-text {
  font-size: 1.25rem; /* 文字字体大小 */
  color: #666; /* 字体颜色 */
  line-height: 1.6; /* 行高 */
}

form {
  background-color: #ffffff; /* 表单背景色 */
  padding: 20px; /* 表单内边距 */
  border-radius: 10px; /* 表单圆角 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 表单阴影 */
  margin: 0; /* 移除表单外边距 */
}

form .form-label {
  font-size: 1.25rem; /* 标签字体大小 */
  font-weight: bold; /* 加粗 */
  color: #333; /* 字体颜色 */
}

form .form-control {
  font-size: 1rem; /* 输入框字体大小 */
  padding: 10px; /* 输入框内边距 */
  margin-bottom: 15px; /* 输入框底部外边距 */
}

form .btn-primary {
  font-size: 1.25rem; /* 按钮字体大小 */
  padding: 10px 20px; /* 按钮内边距 */
}
/* Custom button styles */
/* Custom button styles */
.custom-btn {
  background-color: #23303f !important; /* 你想要的颜色 */
  border-color: #23303f !important; /* 你想要的颜色 */
  color: #fff !important; /* 白色文本 */
}

.custom-btn:hover {
  background-color: #123265 !important; /* 悬停时的颜色 */
  border-color: #123265 !important; /* 悬停时的颜色 */
}

.img-fluid {
  max-width: 100%; /* 图片最大宽度 */
  height: auto; /* 图片高度自动 */
  border-radius: 10px; /* 图片圆角 */
}
.intro-container img {
    background: none; /* 确保没有背景 */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); /* 添加阴影，你可以根据需要调整大小和颜色 */
}
.intro-container {
    border: none; /* 移除边框 */
    background: none; /* 确保没有背景 */
    box-shadow: none; /* 移除任何阴影，如果不需要的话 */
}
.no-padding {
    padding: 0;  /* 移除内边距 */
}

