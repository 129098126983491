.kgvis-box {
    height: 800px;
    width: 100%;
    position: relative;
    background: white;
    borderRadius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    boxShadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px;
    overflow: hidden
}

.logo-image{
    max-width: 100%; /* Fill the width of the container */
  max-height: 100%; /* Fill the height of the container */
  object-fit: scale-down; /* Maintains aspect ratio */
  padding: 0px;
}