.new-page .new-page-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
}

.new-page .kg-section {
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 20px 0;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.new-page .content-section {
  display: grid;
  grid-template-rows: auto 1fr; /* 图片和文本垂直排列 */
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.new-page .text-block {
  text-align: justify; /* 如果你希望文本两端对齐可以使用 justify */
  max-width: 1000px; /* 设置最大宽度为800px */
  margin: auto; /* 使文本块在其容器内居中 */
  padding: 10px 20px; /* 上下10px, 左右20px的内边距 */
}


.new-page img {
  width: 100%;
  max-width: 900px;
  height: auto;
  border-radius: 8px;
  margin: auto; /* 图片居中显示 */
}

.new-page h1, .new-page h2 {
  text-align: center;
}

.new-page h3 {
  color: #0056b3;
}

.new-page p {
  font-size: 16px;
  line-height: 1.5;
  color: #666;
}
